// Dashboard.js
// Dashboard.js
// Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import PDFExport from '../../components/PDFExport';
import ExcelExport from '../../components/ExcelExport';
import * as XLSX from 'xlsx';

function Transaction () {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showPDF, setShowPDF] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedDateRes, setSelectedDateRes] = useState(new Date().toISOString().slice(0, 10));
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  
  const removeCurrencyAndUnit = (data) => {
    return data.map((row) => {
      return row.map((cell) => {
        return cell.replace('Rp. ', '').replace(' KWH', '');;
      });
    });
  };

  const handleSort = (columnName) => {
    if (columnName === sortBy) {
      // Jika kolom yang sama diklik lagi, ubah arah pengurutan
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Jika kolom yang berbeda diklik, atur kolom baru sebagai kolom yang diurutkan
      setSortBy(columnName);
      setSortDirection('asc');
    }
  };
  
  const renderTableRows = () => {
    let sortedData = [...tableData];
    if (sortBy) {
      sortedData.sort((a, b) => {
        if (sortDirection === 'asc') {
          if (typeof a[sortBy] === 'string') {
            return a[sortBy].localeCompare(b[sortBy]);
          } else {
            return a[sortBy] - b[sortBy];
          }
        } else {
          if (typeof a[sortBy] === 'string') {
            return b[sortBy].localeCompare(a[sortBy]);
          } else {
            return b[sortBy] - a[sortBy];
          }
        }
      });
    }
    
    return sortedData.map((row, index) => (
      <tr key={index}>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.session_id}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.user_name}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.device_name}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.splu_sn.substring(row.splu_sn.length - 3).substring(0, 2)}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.consumed} KWH</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>Rp. {row.used}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>Rp. {row.packet}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>Rp. {row.payback}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>Rp. {row.rest}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.timespan}</td>
        <td style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }}>
          <button hidden className="btn btn-sm btn-success">
            <i className="bi bi-info-circle"></i> Detail
          </button>
          Not available
        </td>
      </tr>
    ));
  }
  const tableRefs = useRef(null);
  
  const searchData = async () => {
   setLoading(true);  
    try {
      const token = localStorage.getItem('user-token');
      if (!token) {
        console.error('Token not found in localStorage.');
        localStorage.clear();
        navigate('/auth/login');
        return;
      }
      
      const response = await axios.post('https://spluv2.listrikkita.net/api/search-transaction', {
          "search-type": 1,
          "search-value": selectedDate,
          "reserve-value": selectedDateRes
        }, {
        headers: {
          Authorization: `${token}`,
        },
      });
      
      if (response.data.code === 200 && response.data.status) {
        // Set data tabel baru
        setTableData(response.data.data);
        
        document.querySelector("#count_transcation").textContent = response.data.meta.transaction_count + " Transaction";
        document.querySelector("#count_kwh").textContent = response.data.meta.transaction_kwh + " Kwh";
        document.querySelector("#count_payment").textContent = "Rp. " + response.data.meta.transaction_payment;
      }
      else if ((response.data.code === 401 || response.data.code === 403) && !response.data.status) {
        localStorage.clear();
        navigate('/auth/login');
        return;
      }
      else {
        alert('data not found');
      }
    } catch (error) {
      alert('unexpected exception occured please try again later');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const exportToExcel = () => {
    /*// Ambil referensi ke elemen tabel Anda, misalnya dengan menggunakan ref
    const tableRef = tableRefs; // Gantilah dengan referensi ke tabel Anda
  
    // Buat array kosong untuk menyimpan data tabel
    const data = [];
  
    // Loop melalui setiap baris dalam tabel
    for (let i = 0; i < tableRef.current.rows.length; i++) {
      const row = tableRef.current.rows[i];
      const rowData = [];
  
      // Loop melalui setiap sel dalam baris
      for (let j = 0; j < row.cells.length; j++) {
        rowData.push(row.cells[j].textContent); // Menggunakan textContent bukan innerText
      }
  
      data.push(rowData);
    }
  
    // Hapus "Rp." dan " Kwh" dari data
    const cleanedData = removeCurrencyAndUnit(data);
    // Buat workbook dan worksheet
    const ws = XLSX.utils.aoa_to_sheet(cleanedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Simpan workbook sebagai file Excel
    XLSX.writeFile(wb, 'splu_transaction'+Date.now()+'.xlsx');*/
    // Ambil referensi ke elemen tabel Anda, misalnya dengan menggunakan ref
    const tableRef = tableRefs; // Gantilah dengan referensi ke tabel Anda
  
    // Buat array kosong untuk menyimpan data tabel
    const data = [];
  
    // Loop melalui setiap baris dalam tabel
    for (let i = 0; i < tableRef.current.rows.length; i++) {
      const row = tableRef.current.rows[i];
      const rowData = [];
  
      // Loop melalui setiap sel dalam baris
      for (let j = 0; j < row.cells.length; j++) {
        rowData.push(row.cells[j].textContent); // Menggunakan textContent bukan innerText
      }
  
      data.push(rowData);
    }
  
    // Hapus "Rp." dan " Kwh" dari data
    const cleanedData = removeCurrencyAndUnit(data);
  
    // Menghitung total kolom 5 hingga 8
    let total5 = 0;
    let total6 = 0;
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR', // Ganti sesuai dengan kode mata uang yang sesuai
    });
    for (let i = 0; i < cleanedData.length; i++) {
        let col5Value = cleanedData[i][5].replace(/\./g, '').replace(/,/g, '.');
      
        total5 += parseFloat(cleanedData[i][4]) || 0;
        total6 += parseFloat(col5Value) || 0;
    }
    const formattedTotal6 = formatter.format(total6);
  
    // Memasukkan total ke dalam kolom 5 hingga 8 pada baris terakhir
    const lastRow = ["", "", "", "Total", total5.toFixed(3).toString().replace(/\./g, ',') + " Kwh", formattedTotal6, "", "", "", "", ""];
    cleanedData.push(lastRow);
    for (let i = 1; i < cleanedData.length - 1; i++) {
      cleanedData[i][4] = parseFloat(cleanedData[i][4]); 
      cleanedData[i][5] = parseFloat(cleanedData[i][5].replace(".", "").replace(",", ".")); 
      cleanedData[i][6] = parseFloat(cleanedData[i][6].replace(".", "").replace(",", ".")); 
      cleanedData[i][7] = parseFloat(cleanedData[i][7].replace(".", "").replace(",", "."));
      cleanedData[i][8] = parseFloat(cleanedData[i][8].replace(".", "").replace(",", "."));
    }
    // Buat workbook dan worksheet
    const ws = XLSX.utils.aoa_to_sheet(cleanedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Simpan workbook sebagai file Excel
    XLSX.writeFile(wb, 'splu_transaction' + Date.now() + '.xlsx');
  };
  
  useEffect(() => {
    const fetchData = async () => {
      setTableData([]);
      try {
        const token = localStorage.getItem('user-token');
        if (!token) {
          console.error('Token not found in localStorage.');
          localStorage.clear();
          navigate('/auth/login');
          return;
        }
        
        const response = await axios.get('https://spluv2.listrikkita.net/api/base-tranasction', {
          headers: {
            Authorization: `${token}`,
          },
        });
        
        if (response.data.code === 200 && response.data.status) {
          setTableData(response.data.data);
          document.querySelector("#count_transcation").textContent = response.data.meta.transaction_count + " Transaction";
          document.querySelector("#count_kwh").textContent = response.data.meta.transaction_kwh + " Kwh";
          document.querySelector("#count_payment").textContent = "Rp. " + response.data.meta.transaction_payment;
        }
        else if ((response.data.code === 401 || response.data.code === 403) && !response.data.status) {
          localStorage.clear();
          navigate('/auth/login');
          return;
        }
        else {
          alert('data not found');
        }
      } catch (error) {
        alert('unexpected exception occured please try again later');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    
    setTimeout(() => {
      setLoading(false);
    }, 20000); 
  }, []);
  
  return (
    <React.Fragment>
      {loading && <LoadingSpinner />}
      <div className="container mt-3">
        <div className="row" style={{ textAlign: 'left', color: 'black', marginBottom: '10px', borderBottom: '2px solid black', fontSize: '28px', paddingBottom: '12px', paddingTop: '12px', paddingRight: '12px' }}>
          <h1>Transaction</h1>
        </div>
        <div className="row mt-1 mb-3">
          <div className="col-md-4 col-sm-6">
            <div className="item bg-primary d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
              <p className="mb-0">Total Transaction</p>
              <p className="mb-0" id={ 'count_transcation' }>0 Transaction</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="item bg-success d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
              <p className="mb-0">Total KWH Consumed</p>
              <p className="mb-0" id={ 'count_kwh' }>0 Kwh</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="item bg-secondary d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
              <p className="mb-0">Payment Total</p>
              <p className="mb-0" id={ 'count_payment' }>Rp. 0</p>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-button"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-button"
                value={selectedDateRes}
                onChange={(e) => setSelectedDateRes(e.target.value)}
              />
              <button className="btn btn-outline-secondary" type="button" onClick={searchData}>
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="col-md-6 text-end">
            <button className="btn btn-primary mx-2" onClick={exportToExcel}>Export Excel</button>
            <button hidden className="btn btn-primary mx-2" onClick={() => setShowPDF(!showPDF)}>Export PDF</button>
          </div>
        </div>
  
        <table className="table" ref={tableRefs} style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col">Transactoin ID</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('user_name')}>Nama customer</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('device_name')}>SPLU</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden',  textOverflow: "ellipsis" }} scope="col">Slot ID</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('consumed')}>KWH Digunakan</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('used')}>Rp (Digunakan)</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('packet')}>Rp (Harga Paket)</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('payback')}>Rp (Payback)</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('rest')}>Rp (Saldo Tersisa)</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', color: '#376DFF', cursor: 'pointer', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col" onClick={() => handleSort('timespan')}>Waktu</th>
              <th style={{ width: '10%', textAlign: 'left', padding: '8px', overflow: 'hidden', textOverflow: "ellipsis" }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              renderTableRows()
            ) : (
              <tr>
                <td colSpan="10">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default Transaction;