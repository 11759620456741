import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './guest/Login';
import Auth from './auth/Auth';
import App from './App';
import ProtectedRoute from './util/ProtectedRoute';
import Dashboard from './portal/dashboard/Dashboard';
import SpluData from './portal/spluData/SpluData';
import Transaction from './portal/transaction/Transaction';
import Support from './portal/support/Support';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <Routes>
          <Route path='/auth' element={<Auth />}>
              <Route path='login' element={<Login />} />
          </Route>
          <Route path="/" element={
            <>
              <Navigate to="/dashboard" /> {/* Redirect from "/" to "/dashboard" */}
            </>
          }/>
          <Route path="/dashboard" element={<App />}>
              <Route path='' element={
                  <ProtectedRoute>
                      <Dashboard />
                  </ProtectedRoute>
              } />
          </Route>
          <Route path="/splu-data" element={<App />}>
              <Route path='' element={
                  <ProtectedRoute>
                      <SpluData />
                  </ProtectedRoute>
              } />
          </Route>
          <Route path="/transaction" element={<App />}>
              <Route path='' element={
                  <ProtectedRoute>
                      <Transaction />
                  </ProtectedRoute>
              } />
          </Route>
          <Route path="/support" element={<App />}>
              <Route path='' element={
                  <ProtectedRoute>
                      <Support />
                  </ProtectedRoute>
              } />
          </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
