// Dashboard.js
// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import '../../style/CustomerSupport.css'; // Add your CSS for styling

function Support () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('user-token');
        
        // Check if the token is not null or undefined before using it
        if (token) {
          // Use the token here, e.g., for making API requests
          console.log('Token:', token);
        } else {
          // Handle the case where the token is not found in localStorage
          console.error('Token not found in localStorage.');
          return;
        }
        
        const response = await axios.get('https://spluv2.listrikkita.net/api/general-data', {
          headers: {
            Authorization: `${token}`,
          },
        });
        
        console.log(response);
        if (response.status === 200 && response.data.status) {
          setData(response.data.data); // Set the response data to the state
        }
        else if (response.status === 401 && !response.data.status && response.data.message === 'Authorization Token is expired') {
          localStorage.clear();
          navigate('/auth/login');
          return;
        }
        else {
          console.error('Error: Invalid response');
          alert('general data not found please contact system provider');
        }
      } catch (error) {
        alert('unexpected exception occured please try again later');
        console.error('Error:', error);
      } finally {
        setLoading(false); // Always stop loading whether successful or not
      }
    };

    fetchData();
    
    // Simulate an async operation (e.g., fetching data)
    setTimeout(() => {
      setLoading(false); // Turn off the loading spinner after some time
    }, 20000); // Change the time as needed
  }, []);
  
  return (
    <React.Fragment>
      {loading && <LoadingSpinner />}
      <div className="container mt-3">
        <div className="row" style={{ textAlign: 'left', color: 'black', marginBottom: '10px', borderBottom: '2px solid black', fontSize: '28px', paddingBottom: '12px', paddingTop: '12px', paddingRight: '12px' }}>
          <h1>Support Center</h1>
        </div>
        <div className="customer-support-page">
          <section className="search-section">
            {/* Search bar for finding support articles */}
            <div className="search-bar">
              <input type="text" placeholder="Search for help" />
              <button>Search</button>
            </div>
          </section>
          <section className="knowledge-base">
            {/* Knowledge base section with support articles */}
            <h2>Knowledge Base</h2>
            <ul>
              <li>
                <a href="#">Empty data occured?</a>
              </li>
              <li>
                <a href="#">Device information misleads</a>
              </li>
              <li>
                <a href="#">Contacting support</a>
              </li>
              {/* Add more support articles here */}
            </ul>
          </section>
          <section className="contact-form">
            {/* Contact form for submitting support requests */}
            <h2>Contact Us</h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message"></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Support;