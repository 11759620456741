import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const defaultProps = {
      center: {
        lat: -8.322190205614577,
        lng: 115.19236986917853
      },
      zoom: 12
    };
    
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('user-token');
          if (!token) {
            console.error('Token not found in localStorage.');
            localStorage.clear();
            navigate('/auth/login');
            return;
          }
          
          const response = await axios.get('https://spluv2.listrikkita.net/api/general-data', {
            headers: {
              Authorization: `${token}`,
            },
          });
          
          if (response.data.code === 200 && response.data.status) {
            document.querySelector("#available_slot").textContent = response.data.meta.available + " Slot";
            document.querySelector("#used_slot").textContent = response.data.meta.used + " Slot";
            document.querySelector("#offline_slot").textContent = response.data.meta.offline + " Slot";
            document.querySelector("#maintance_slot").textContent = response.data.meta.maintance + " Slot";
          }
          else if ((response.data.code === 401 || response.data.code === 403) && !response.data.status) {
            localStorage.clear();
            navigate('/auth/login');
            return;
          }
          else {
            alert('data not found');
          }
        } catch (error) {
          alert('unexpected exception occured please try again later');
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
      
      // Simulate an async operation (e.g., fetching data)
      setTimeout(() => {
        setLoading(false); // Turn off the loading spinner after some time
      }, 20000); // Change the time as needed
      
      const intervalId = setInterval(() => {
        fetchData();
      }, 5000); // 5000 milidetik = 5 detik
      
      return () => {
        clearInterval(intervalId);
      };
    }, []);
    
    return (
        <React.Fragment>
          {loading && <LoadingSpinner />}
          <div className="container mt-3">
            <div className="row" style={{ textAlign: 'left', color: 'black', marginBottom: '10px', borderBottom: '2px solid black', fontSize: '28px', paddingBottom: '12px', paddingTop: '12px', paddingRight: '12px' }}>
              <h1>Dashboard</h1>
            </div>
            <div className="row mt-1">
              <div className="col-md-3 col-sm-6">
                <div className="item bg-primary d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
                  <p className="mb-0">Available</p>
                  <p className="mb-0" id={ 'available_slot' }>0 Slot</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="item bg-success d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
                  <p className="mb-0">In Transaction</p>
                  <p className="mb-0" id={ 'used_slot' }>0 Slot</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="item bg-danger d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
                  <p className="mb-0">Offline</p>
                  <p className="mb-0" id={ 'offline_slot' }>0 Slot</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="item bg-secondary d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
                  <p className="mb-0">Maintance</p>
                  <p className="mb-0" id={ 'maintance_slot' }>0 Slot</p>
                </div>
              </div>
            </div>
            <div className="row" style={{ textAlign: 'left', color: 'black', marginTop: '10px', marginBottom: '5px', borderBottom: '2px solid black', fontSize: '28px', padding: '12px' }}>
              <h3>SPLU Location</h3>
            </div>
            <div className="row mt-1" style={{ height: '500px', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyDcdYFakFrI0ZqEnz7oOJmy6CHXZ9hXIcg" }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}>
                      <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                      />
                </GoogleMapReact>
            </div>
          </div>
        </React.Fragment>
    )
}
export default Dashboard;