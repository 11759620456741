import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
{
	title: "Dashboard",
	path: "/dashboard",
	icon: <AiIcons.AiFillHome />,
},
{
	title: "SPLU Data",
	path: "/splu-data",
	icon: <IoIcons.IoIosPaper />,
},
{
	title: "Transaction",
	path: "/transaction",
	icon: <FaIcons.FaEnvelopeOpenText />,
},
{
	title: "Support",
	path: "/support",
	icon: <IoIcons.IoMdHelpCircle />,
},
];
