import React from "react";
import { Outlet } from "react-router-dom";
import AuthFooter from "./footer/AuthFooter";
import AuthNavbar from "./navbar/AuthNavbar";
import { IMG_LG_BCKG } from '../config';
const Auth = () => {
    return (
        <React.Fragment>
            <div style={{
              backgroundImage: `url(${IMG_LG_BCKG})`, // Replace with your image path
              backgroundSize: 'cover', // Adjust the background size as needed
              height: '100vh', // Set the div height to 100% of the viewport height
              backgroundAttachment: 'fixed', // Keep the background fixed
              // Other styles as needed
            }}>
                <AuthNavbar />
                <Outlet />
                <AuthFooter />
            </div>
        </React.Fragment>
    );
}
export default Auth;