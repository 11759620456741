import React, { useState, useEffect } from 'react';
import '../style/LoadingSpinner.css'; // Import the CSS file for styling

const LoadingSpinner = () => {
  const [loadingText, setLoadingText] = useState('Loading'); // Initial loading text
  const loadingTextVariations = ['.', '..', '...']; // Text variations for animation
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    // Create an interval to animate the loading text
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % loadingTextVariations.length);
    }, 500);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-spinner-overlay">
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p className="loading-text">{loadingText + loadingTextVariations[textIndex]}</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
